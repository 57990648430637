@font-face {
    font-family: "Light"; /*Can be any text*/
    src: local("AirbnbCerealLight"),
        url("./fonts/Minecraftia.ttf") format("truetype");
}

* {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    padding: 0;
    margin: 0;
}

body {
    background-color: #000;
}

::-webkit-scrollbar {
    display: none;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 16px;
}

th {
    background-color: #111;
    color: white;
    position: sticky;
    top: 0;
    cursor: pointer;
}

/* dark table stripped */
tr:nth-child(even) {
    background-color: #222;
    color: #fff;
}
tr:nth-child(odd) {
    background-color: #333;
    color: #fff;
}

tr:hover {
    background-color: #555;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
}

.loading h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.error {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    flex-direction: column;
    z-index: 1;
}

.error h1 {
    color: #fff;
}

.error p {
    color: #fff;
}

.player * {
    font-family: "Light";
}

td.player {
    display: flex;
    align-items: center;
}

.player_name {
    padding: 16px 0px;
    font-size: 1rem;
}

.player_guild {
    padding: 16px 0px;
    font-size: 1rem;
}

td.player img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

@media (max-width: 567px) {
    th,
    td {
        padding: 8px;
    }
    th {
        font-size: 0.8rem;
    }
    td.player {
        display: flex;
        align-items: center;
    }
    .player_name {
        padding: 8px 0px;
        font-size: 0.8rem;
    }
    .player_guild {
        padding: 8px 0px;
        font-size: 0.8rem;
    }
    td.player img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}

@media (max-width: 450px) {
    th,
    td {
        padding: 4px;
        font-size: 0.6rem;
    }
    th {
        font-size: 0.6rem;
    }
    td.player {
        display: flex;
        align-items: center;
    }
    .player_name {
        padding: 4px 0px;
        font-size: 0.6rem;
    }
    .player_guild {
        padding: 4px 0px;
        font-size: 0.6rem;
    }
    td.player img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}